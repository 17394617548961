/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 MUI Base Styles
import colors from '@/assets/theme/base/colors';
import borders from '@/assets/theme/base/borders';
import boxShadows from '@/assets/theme/base/boxShadows';
import { themeBase } from '@/assets/theme/base/borders';

// Argon Dashboard 2 MUI Helper Function
import rgba from '@/assets/theme/functions/rgba';

const { black, white } = colors;
const { borderWidth, borderRadius } = borders;
const { cardBoxShadow } = boxShadows;

const card = {
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minWidth: 0,
      wordWrap: 'break-word',
      backgroundColor: white.main,
      backgroundClip: 'border-box',
      border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      borderRadius: borderRadius.l,
      boxShadow: cardBoxShadow,
      padding: themeBase,
      margin: themeBase,
    },
  },
};

export default card;
